import "./App.css";

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<p>En ryddekalender for alle!</p>
			</header>
		</div>
	);
}

export default App;
